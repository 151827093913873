import {
  ON_INCOME_ACTIVE_READ,
  ON_INCOME_ACTIVE_READING,
  ON_INCOME_HISTORY_READ,
  ON_INCOME_HISTORY_READING,
  ON_INCOME_HISTORY_SEARCH_READING,
  ON_INCOME_HISTORY_SEARCH_READ,
  ON_INCOME_VALUE_CHANGE,
} from './types';
import {
  getIncomesActiveApi,
  addIncomeApi,
  dischargeIncomeApi,
  updateIncomeApi,
  addNewsIncomeApi,
  getIncomesHistoryApi,
  addEpicrisisIncomeApi,
  updateHistoryIncomeApi,
  rollbackIncomeApi,
  changeTypeIncomeApi,
  getIncomesFilterHistoryApi,
} from '../api/income';
import { getAccessTokenApi } from '../api/auth';
import { message, notification } from 'antd';
import { onUserDataRead } from './UserDataActions';
import { onStatsBedsRead } from './BedActions';

export const onIncomeValueChange = (payload) => {
  return { type: ON_INCOME_VALUE_CHANGE, payload };
};

export const onIncomeActiveRead = (type) => async (dispatch) => {
  dispatch({ type: ON_INCOME_ACTIVE_READING });
  const token = await getAccessTokenApi();

  return await getIncomesActiveApi(token, type)
    .then((response) => {
      dispatch({ type: ON_INCOME_ACTIVE_READ, payload: response.incomes });
      response.code !== 200 &&
        notification['warning']({ message: response.message });
    })
    .catch(() => {
      return;
    });
};

export const onIncomeHistoryRead = (limit) => async (dispatch) => {
  dispatch({ type: ON_INCOME_HISTORY_READING });
  const token = await getAccessTokenApi();

  return getIncomesHistoryApi(token, limit)
    .then(async (response) => {
      if (response?.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        await dispatch({
          type: ON_INCOME_HISTORY_READ,
          payload: { incomes: response.incomes, count: response.count },
        });
      }
    })
    .catch((err) => {
      return err;
    });
};

export const onIncomeHistoryLoadMore =
  (incomesHistory, limit, count) => async (dispatch) => {
    dispatch({ type: ON_INCOME_HISTORY_READING });
    const token = await getAccessTokenApi();

    return getIncomesHistoryApi(token, limit, count)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          await dispatch({
            type: ON_INCOME_HISTORY_READ,
            payload: {
              incomes: incomesHistory.concat(response.incomes),
              count: count + response.count,
            },
          });
          response.count === 0
            ? message.warning('No hay mas internaciones.')
            : message.success(`Se cargaron 10 internaciones mas`);
        }
      })
      .catch(() => {
        return;
      });
  };

export const onIncomeFilterHistoryRead =
  ({ search, typeIncome, dni, socialWork, dateStart, dateEnd }) =>
  async (dispatch) => {
    dispatch({ type: ON_INCOME_HISTORY_SEARCH_READING });
    const token = getAccessTokenApi();

    return await getIncomesFilterHistoryApi(
      token,
      dni ? dni : search,
      typeIncome,
      socialWork,
      dateStart,
      dateEnd
    )
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          await dispatch({
            type: ON_INCOME_HISTORY_SEARCH_READ,
            payload: { incomes: response.incomes },
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

export const onIncomeCreate = (incomeData) => async (dispatch) => {
  const token = await getAccessTokenApi();

  return addIncomeApi(token, incomeData)
    .then(async (response) => {
      if (response?.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        notification['success']({ message: response.message });
        window.history.back();
        await dispatch(onUserDataRead());
      }
    })
    .catch(() => {
      notification['error']({
        message: 'Error del servidor, intente mas tarde.',
      });
    });
};

export const onIncomeUpdate = (incomeData, id) => async (dispatch) => {
  const token = await getAccessTokenApi();

  return updateIncomeApi(token, incomeData, id)
    .then((response) => {
      if (response?.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        notification['success']({ message: response.message });
        window.history.back();
      }
    })
    .catch(() => {
      notification['error']({
        message: 'Error del servidor, intente mas tarde.',
      });
    });
};

export const onIncomeHistoryUpdate = (incomeData, id) => async (dispatch) => {
  const token = await getAccessTokenApi();

  return await updateHistoryIncomeApi(token, incomeData, id)
    .then((response) => {
      if (response?.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        notification['success']({ message: response.message });
        window.history.back();
      }
    })
    .catch(() => {
      notification['error']({
        message: 'Error del servidor, intente mas tarde.',
      });
    });
};

export const onIncomeDischarge =
  (incomeData, id, type, setIsVisibleModal) => async (dispatch) => {
    const token = await getAccessTokenApi();

    return dischargeIncomeApi(token, incomeData, id)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          setIsVisibleModal(false);
          await dispatch(onStatsBedsRead());
          await dispatch(onIncomeActiveRead(type));
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };

export const onIncomeAddNews =
  (incomeData, id, type, setIsVisibleModal) => async (dispatch) => {
    const token = await getAccessTokenApi();

    return addNewsIncomeApi(token, incomeData, id)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          setIsVisibleModal(false);
          type && (await dispatch(onIncomeActiveRead(type)));
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };

export const onIncomeAddEpicrisis = (incomeData, id) => async (dispatch) => {
  let token = await getAccessTokenApi();

  return addEpicrisisIncomeApi(token, incomeData, id)
    .then((response) => {
      if (response?.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        notification['success']({ message: response.message });
        window.history.back();
      }
    })
    .catch(() => {
      notification['error']({
        message: 'Error del servidor, intente mas tarde.',
      });
    });
};

export const onIncomeChangeFloor =
  (id, data, type, setIsVisibleModal) => async (dispatch) => {
    let token = await getAccessTokenApi();

    return await changeTypeIncomeApi(id, data, token)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          setIsVisibleModal(false);
          await dispatch(onIncomeActiveRead(type));
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };

export const onIncomeRollback =
  (id, data, setIsVisibleModal, count) => async (dispatch) => {
    let token = await getAccessTokenApi();

    return await rollbackIncomeApi(id, data, token)
      .then(async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          setIsVisibleModal(false);
          await dispatch(onIncomeHistoryRead(count));
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };
