import React, { useState } from 'react';
import { Button, Collapse, Tag, Tooltip } from 'antd';
import Spin from '../../Spin';
import moment from 'moment';
import Modal from '../../Modal';
import './PatientList.scss';
import { Link } from 'react-router-dom';
import PatientForm from '../PatientForm';
import {
  EditOutlined,
  StarFilled,
  InfoCircleOutlined,
  FilePdfOutlined,
  HistoryOutlined,
  CheckOutlined,
  WhatsAppOutlined,
  ScheduleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { onAddPatientFavourite } from '../../../actions';
import PatientDescription from '../PatientDescription';
import { ROLES } from '../../../utils/constants';
import { TableChart } from '../../Charts/TableChart';
import { getHistoryTurnsPatientApi } from '../../../api/turn';
import { getAccessTokenApi } from '../../../api/auth';

export default function PatientList({ patient, turn, addTurn }) {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);

  const dispatch = useDispatch();
  const { favouritePatients, role, modulesAccess } = useSelector(
    (store) => store.userData?.user
  );
  const userId = useSelector((store) => store.userData?.user?._id);

  const { patients, searchPatient, loading } = useSelector(
    (store) => store.patientData
  );
  const { organizationType, studies } = useSelector(
    (store) => store.tenantData
  );

  const editPatient = (patient) => {
    setIsVisibleModal(true);
    setModalTitle('Editar Paciente');
    setModalContent(
      <PatientForm setIsVisibleModal={setIsVisibleModal} patient={patient} />
    );
  };

  if (loading) return <Spin />;

  let textUrlEncode = turn
    ? `%2A%C2%A1%20Hola%20%20${
        patient.name
      }%20%21%2A%0A%0ATe%20recordamos%20que%20tienes%20un%20turno%20para%20el%20${moment(
        turn.hourStart
      ).format('DD/MM HH:mm')}hs`
    : `%2A%C2%A1%20Hola%20%20${patient.name}%20%21%2A`;

  const viewHistoryTurns = async () => {
    const token = await getAccessTokenApi();
    let historyTurns = await getHistoryTurnsPatientApi({
      token,
      patient: patient._id,
    });

    setIsVisibleModal(true);
    setModalTitle(
      <span style={{ textTransform: 'capitalize' }}>
        Turnos: {patient?.name}
      </span>
    );
    setModalContent(
      <TableChart
        generate={patient._id}
        data={historyTurns}
        sendColumns={[
          {
            title: 'Fecha',
            dataIndex: 'hourStart',
            key: 'hourStart',
            align: 'center',
            render: (hourStart) => (
              <span>{moment(hourStart).format('DD/MM/YY HH:mm')}</span>
            ),
            sorter: (a, b) =>
              moment(a.hourStart).unix() - moment(b.hourStart).unix(),
          },
          {
            title: 'Efector',
            dataIndex: 'medicoEfector',
            key: 'medicoEfector',
            render: (text, record, index) =>
              record.medicoEfector
                ? record.medicoEfector
                : record.medicoTurnera,
            sorter: (a, b) => a.medicoEfector?.localeCompare(b.medicoEfector),
          },
          {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text, record, index) =>
              (record.status ||
                moment(record.hourStart).unix() >= moment().unix()) && (
                <Tag
                  color={
                    record?.status === 'Sala de Espera'
                      ? 'yellow'
                      : record?.status === 'Atendido'
                      ? 'green'
                      : record?.status === 'Ausente'
                      ? 'red'
                      : 'orange'
                  }
                  icon={
                    record?.status === 'Sala de Espera' ? (
                      <SyncOutlined />
                    ) : record?.status === 'Atendido' ? (
                      <CheckCircleOutlined />
                    ) : turn?.status === 'Ausente' ? (
                      <MinusCircleOutlined />
                    ) : (
                      <ExclamationCircleOutlined />
                    )
                  }
                >
                  {record?.status || 'Pendiente'}
                </Tag>
              ),
          },
          {
            title: 'Valor',
            dataIndex: 'dataChart',
            key: 'price',
            render: (text, record) =>
              role === 2 || role === 0 ? (
                <span>
                  {record.price?.toLocaleString('es-AR', {
                    style: 'currency',
                    currency: 'ARS',
                    minimumFractionDigits: 2,
                  })}
                </span>
              ) : (
                record.medicoEfectorId === userId && (
                  <span>
                    {record.pricePercent?.toLocaleString('es-AR', {
                      style: 'currency',
                      currency: 'ARS',
                      minimumFractionDigits: 2,
                    })}
                  </span>
                )
              ),
          },
          {
            title: 'Estudio',
            dataIndex: 'studies',
            key: 'studies',
            render: async (text, record, index) => {
              let { name } = (!record?.medicoTurnera &&
                (await studies.find(
                  (studie) => record.user === studie.id
                ))) || { name: '' };
              return record.studies ? record.studies?.name : name;
            },
            sorter: (a, b) => a?.studies?.name.localeCompare(b?.studies?.name),
          },
          {
            title: 'Observación',
            dataIndex: 'description',
            key: 'description',
          },
        ]}
        pageSize={5}
      />
    );
  };

  return (
    <>
      <Collapse
        className="card-patient"
        defaultActiveKey={patients.length < 4 && 1}
      >
        <Collapse.Panel
          collapsible="header"
          showArrow={false}
          header={
            <Tooltip title="Presione para ver/ocultar detalle">
              {patient.name} ({moment().diff(patient.birthDate, 'years')} años){' '}
            </Tooltip>
          }
          key={1}
          extra={
            <div className="card-patient_extra">
              {(role !== ROLES.SECRETARY.value ||
                modulesAccess?.includes('Ver h.c amb')) && (
                <Link
                  to={`/ambulatory-consultations/${patient._id}/${patient.name}`}
                >
                  <Tooltip title="Informacion Paciente">
                    <Button type="link" style={{ color: '#fff' }}>
                      <InfoCircleOutlined />
                    </Button>
                  </Tooltip>
                </Link>
              )}
              {patient.notificationPhone.number && (
                <Tooltip title="Enviar WhatsApp">
                  <Button type="link">
                    <a
                      href={`https://wa.me/${patient?.notificationPhone?.countryCode}${patient?.notificationPhone?.regionCode}${patient?.notificationPhone?.number}?text=${textUrlEncode}`}
                      target="_blank"
                      referrerPolicy="origin"
                    >
                      <WhatsAppOutlined style={{ color: '#fff' }} />
                    </a>
                  </Button>
                </Tooltip>
              )}

              <Tooltip title="Favorito">
                <Button
                  type="link"
                  style={{ color: '#fff' }}
                  onClick={() => {
                    dispatch(
                      onAddPatientFavourite(
                        patient._id,
                        favouritePatients.find((i) => i === patient._id)
                          ? 'delete'
                          : favouritePatients?.length < 1000
                          ? 'add'
                          : 'maxLimit',
                        searchPatient
                      )
                    );
                  }}
                >
                  <StarFilled
                    style={
                      favouritePatients.find((i) => i === patient._id) && {
                        color: 'yellow',
                      }
                    }
                  />
                </Button>
              </Tooltip>

              <>
                <Tooltip title="Editar">
                  <Button
                    style={{ color: '#fff' }}
                    type="link"
                    onClick={() => editPatient(patient)}
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip>
                {turn && !turn?.patient && (
                  <Tooltip title="Asignar Turno">
                    <Button
                      type="link"
                      style={{ color: '#fff' }}
                      onClick={() => addTurn(patient)}
                    >
                      <CheckOutlined />
                    </Button>
                  </Tooltip>
                )}
              </>
            </div>
          }
        >
          <div className="patient-actions">
            <Tooltip title="Historial de turnos">
              <Button type="link" onClick={() => viewHistoryTurns()}>
                <span className="patient-actions_title">Turnos</span>
                <ScheduleOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Ver Estudios">
              <Link to={`/patient-studies/${patient._id}/${patient.name}`}>
                <Button type="link">
                  <span className="patient-actions_title">Estudios</span>
                  <FilePdfOutlined />
                </Button>
              </Link>
            </Tooltip>
            {organizationType === 'hospitalaria' && (
              <Tooltip title="Ver Internaciones">
                <Link
                  to={`/incomes-history-patient/${patient.dni}/${patient.name}`}
                >
                  <Button type="link">
                    <span className="patient-actions_title">Internaciones</span>
                    <HistoryOutlined />
                  </Button>
                </Link>
              </Tooltip>
            )}
          </div>

          <PatientDescription patient={patient} />
        </Collapse.Panel>
      </Collapse>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'80%'}
      >
        {modalContent}
      </Modal>
    </>
  );
}
