import {
  Form,
  InputNumber,
  Row,
  Col,
  Select,
  Input,
  DatePicker,
  Divider,
  Button,
  Card,
  message,
  Tooltip,
  Radio,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getDrugApi } from '../../api/drug';
import { getAccessTokenApi } from '../../api/auth';
import { getInsumoApi } from '../../api/insumo';
import { addPharmacyApi } from '../../api/pharmacy';
import BackButton from '../../components/BackButton';
import locale from 'antd/es/date-picker/locale/es_ES';
import { useSelector } from 'react-redux';
import ProviderForm from '../../components/ProviderForm/ProviderForm';
import Modal from '../../components/Modal';
import { getProvidersApi } from '../../api/provider';

export default function NewBuyPharmacy() {
  const [drugs, setDrugs] = useState([]);
  const [insumos, setInsumos] = useState([]);
  const [providers, setProviders] = useState([]);

  const [refresh, setRefresh] = useState(false);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);

  const { name, lastname } = useSelector((store) => store.userData?.user);
  const { drugStores } = useSelector((store) => store.tenantData);

  const [buyForm] = Form.useForm();

  useEffect(() => {
    buyForm.setFieldsValue({ products: [{}] });
  }, []);

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();
      try {
        getProvidersApi(token).then((response) => {
          setProviders(response.providers);
        });
        getDrugApi(token).then((response) => {
          setDrugs(response.drugs);
        });
        getInsumoApi(token).then((response) => {
          setInsumos(response.insumos);
        });
      } catch {
        message.error('Error del servidor, intente nuevamente');
      }
    };

    getData();
  }, [isVisibleModal]);

  const onFinish = async (values) => {
    values.type = 'buy';
    values.subTotalPrice = subTotal;
    values.totalIva = iva;
    values.totalPrice = subTotal + iva;
    values.user = `${name} ${lastname}`;
    let token = await getAccessTokenApi();

    addPharmacyApi({ token, pharmacyData: values })
      .then(async (response) => {
        if (response?.code !== 200) {
          message.error(response.message);
        } else {
          message.success(response.message);
          window.history.back();
        }
      })
      .catch(() => {
        message.error('Error del servidor, intente mas tarde.');
      });
  };

  const addProvider = () => {
    setIsVisibleModal(true);
    setModalTitle('Nuevo proveedor');
    setModalContent(<ProviderForm setIsVisibleModal={setIsVisibleModal} />);
  };

  let subTotal = 0;
  let iva = 0;

  buyForm.getFieldValue().products?.map((product) => {
    if (product?.price) {
      subTotal += product.quantity * product.price;
      iva += product.quantity * product.price * (product.iva / 100);
    }
  });

  return (
    <div>
      <Card
        title={
          <>
            <BackButton /> <span>Nueva Compra</span>
          </>
        }
      >
        <Form form={buyForm} onFinish={onFinish}>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="store"
                rules={[{ required: true, message: 'Debe elegir un destino' }]}
                labelCol={{ span: 24 }}
                label="Destino"
              >
                <Select
                  placeholder="Seleccione un destino.. (*)"
                  showSearch
                  optionFilterProp="children"
                >
                  {drugStores?.map((store) => (
                    <Select.Option value={store}>{store}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={16}>
              <Form.Item
                name="name"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: 'Debe indicar un titulo a la compra',
                  },
                ]}
                labelCol={{ span: 24 }}
                label="Concepto"
              >
                <Input placeholder="Compra mensual diciembre 2023" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="date"
                initialValue={moment()}
                rules={[{ required: true, message: 'Debe cargar la fecha' }]}
                labelCol={{ span: 24 }}
                label="Fecha de factura"
              >
                <DatePicker
                  locale={locale}
                  style={{ width: '100%' }}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="provider"
                rules={[
                  { required: true, message: 'Debe elegir un proveedor' },
                ]}
                labelCol={{ span: 24 }}
                label="Proveedor"
              >
                <Select
                  placeholder="Seleccione un proveedor.. (*)"
                  showSearch
                  optionFilterProp="children"
                >
                  <Select.Option disabled>
                    <Button type="link" onClick={addProvider}>
                      + Nuevo proveedor
                    </Button>
                  </Select.Option>
                  {providers?.map((provider, index) => (
                    <Select.Option key={index} value={provider.name}>
                      {provider.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="invoice"
                initialValue=""
                labelCol={{ span: 24 }}
                label="Nro de factura"
                rules={[
                  { required: true, message: 'Debe cargar nro de factura' },
                ]}
              >
                <Input placeholder="Nro de Factura (*)" />
              </Form.Item>
            </Col>
          </Row>

          <Form.List name="products">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Row gutter={24} key={index}>
                      <Divider>Producto Nº{index + 1}</Divider>

                      <Col xs={23} md={12}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'product']}
                          rules={[
                            {
                              required: true,
                              message: 'Debe elegir el producto',
                            },
                          ]}
                          labelCol={{ span: 24 }}
                          label="Producto"
                        >
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Seleccione un producto.. (*)"
                            optionFilterProp="children"
                            onChange={(e, o) => {
                              let data =
                                buyForm.getFieldValue().products[field.name];
                              Object.assign(data, { name: o.key });
                            }}
                          >
                            {drugs?.map((drug, index) => (
                              <Select.Option key={drug.name} value={drug._id}>
                                {drug.name}
                              </Select.Option>
                            ))}
                            {insumos?.map((insumo, index) => (
                              <Select.Option
                                key={insumo.name}
                                value={insumo._id}
                              >
                                {insumo.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={11} md={6}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'iva']}
                          rules={[
                            {
                              required: true,
                              message: 'Debe cargar el iva.',
                            },
                          ]}
                          initialValue={0}
                          labelCol={{ span: 24 }}
                          label="Iva"
                        >
                          <Radio.Group
                            onChange={(e) => {
                              buyForm.getFieldValue().products[field.name]
                                ?.quantity &&
                                buyForm.getFieldValue().products[field.name]
                                  ?.quantity &&
                                setRefresh(!refresh);
                            }}
                          >
                            <Radio value={0}>Excento</Radio>
                            <Radio value={10.5}>10,5 %</Radio>
                            <Radio value={21}>21 %</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs={11} md={6}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'dateExpire']}
                          labelCol={{ span: 24 }}
                          label="Fecha de vencimiento"
                        >
                          <DatePicker
                            picker="month"
                            locale={locale}
                            placeholder="Fecha de vencimiento (opcional)"
                            allowClear
                            style={{ width: '100%' }}
                            onChange={(e) => e?.startOf('month')}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={11} md={8}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'quantity']}
                          rules={[
                            {
                              required: true,
                              message: 'Debe cargar la cantidad',
                            },
                          ]}
                          labelCol={{ span: 24 }}
                          label="Cantidad"
                        >
                          <InputNumber
                            style={{ width: '100%' }}
                            placeholder="Cantidad (*)"
                            min={1}
                            onChange={(e) => {
                              buyForm.getFieldValue().products[field.name]
                                ?.price && setRefresh(!refresh);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={11} md={8}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'price']}
                          rules={[
                            {
                              required: true,
                              message:
                                'Debe cargar el precio unitario. Usar puntos como separador de decimales',
                            },
                          ]}
                          labelCol={{ span: 24 }}
                          label="Precio unitario"
                        >
                          <Input
                            style={{ width: '100%' }}
                            placeholder="Precio unitario (*)"
                            min={1}
                            step="0.01"
                            inputMode="decimal"
                            prefix="$"
                            type="number"
                            onChange={(e) => {
                              buyForm.getFieldValue().products[field.name]
                                ?.quantity && setRefresh(!refresh);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={23} md={7}>
                        Subtotal:{' '}
                        {(
                          buyForm.getFieldValue().products[field.name]
                            ?.quantity *
                            buyForm.getFieldValue().products[field.name]
                              ?.price || 0
                        ).toLocaleString('es-AR', {
                          style: 'currency',
                          currency: 'ARS',
                          minimumFractionDigits: 2,
                        })}
                        <br />
                        Subtotal con IVA:{' '}
                        {(
                          buyForm.getFieldValue().products[field.name]
                            ?.quantity *
                            buyForm.getFieldValue().products[field.name]
                              ?.price +
                            buyForm.getFieldValue().products[field.name]
                              ?.quantity *
                              buyForm.getFieldValue().products[field.name]
                                ?.price *
                              (buyForm.getFieldValue().products[field.name]
                                ?.iva /
                                100) || 0
                        ).toLocaleString('es-AR', {
                          style: 'currency',
                          currency: 'ARS',
                          minimumFractionDigits: 2,
                        })}
                      </Col>

                      {fields.length > 1 ? (
                        <Col span={1} style={{ paddingLeft: '0' }}>
                          <Tooltip title="Eliminar item">
                            <Button
                              type="link"
                              size="large"
                              onClick={() => {
                                remove(field.name);
                              }}
                            >
                              <MinusCircleOutlined />
                            </Button>
                          </Tooltip>
                        </Col>
                      ) : null}
                    </Row>
                  ))}

                  <Form.Item style={{ textAlign: 'right' }}>
                    <Tooltip title="Agregar nuevo item">
                      <Button
                        type="primary"
                        shape="circle"
                        onClick={() => {
                          add();
                        }}
                        size="large"
                      >
                        <PlusOutlined />
                      </Button>
                    </Tooltip>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>

          <div className="prices">
            <span>
              Subtotal:{' '}
              {subTotal.toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
              })}
            </span>
            <br />
            <span>
              IVA:{' '}
              {iva.toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
              })}
            </span>
            <br />
            <span className="prices-total">
              Importe Total:{' '}
              {(subTotal + iva).toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
              })}
            </span>
          </div>

          <Form.Item>
            <Button className="button-submit" type="primary" htmlType="submit">
              Guardar compra
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width="60%"
      >
        {modalContent}
      </Modal>
    </div>
  );
}
