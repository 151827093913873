import React from 'react';
import { Table } from 'antd';
export default function StockTable({
  items,
  id,
  type,
  header,
  search,
  expire,
}) {
  const columns = [
    {
      title: 'Producto',
      width: '50%',
      dataIndex: '_id',
      key: '_id',
      render: (_id) => (
        <span>
          {_id.name}{' '}
          {type === '#buy' && _id?.dateExpire && `(${_id.dateExpire})`}
        </span>
      ),
      sorter: (a, b) => a._id.name.localeCompare(b._id.name),
    },
    {
      title: 'Cantidad',
      width: id ? '10%' : '30%',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (quantity) =>
        type === '#send' ? (
          <span>{Math.abs(quantity).toLocaleString('es-AR')}</span>
        ) : (
          <span>{quantity.toLocaleString('es-AR')}</span>
        ),
      sorter: (a, b) => a.quantity - b.quantity,
    },
  ];

  if (search) {
    var itemsFilters = items.filter((item) =>
      item?._id.name.toLowerCase().trim().includes(search.toLowerCase().trim())
    );
  }

  expire &&
    columns.push({
      title: 'Fecha de Vencimiento',
      dataIndex: '_id',
      key: '_id',
      render: (_id) => (
        <span
          style={
            _id.months <= 2
              ? { color: '#f00' }
              : _id.months >= 3 && _id.months <= 5
              ? { color: '#f5cc7f' }
              : {}
          }
        >
          {_id.dateExpire}
        </span>
      ),
      sorter: (a, b) => a._id.months - b._id.months,
    });

  type === '#buy' &&
    columns.push(
      {
        title: 'Precio Unitario',
        dataIndex: 'unitPrice',
        key: 'unitPrice',
        render: (unitPrice) => (
          <span>
            {unitPrice?.toLocaleString('es-AR', {
              style: 'currency',
              currency: 'ARS',
              minimumFractionDigits: 2,
            })}
          </span>
        ),
        sorter: (a, b) => a.unitPrice - b.unitPrice,
      },
      {
        title: 'Subtotal',
        dataIndex: 'totalProductPrice',
        key: 'totalProductPrice',
        render: (totalProductPrice) => (
          <span>
            {totalProductPrice?.toLocaleString('es-AR', {
              style: 'currency',
              currency: 'ARS',
              minimumFractionDigits: 2,
            })}
          </span>
        ),
        sorter: (a, b) => a.totalProductPrice - b.totalProductPrice,
      },
      {
        title: 'IVA',
        dataIndex: 'iva',
        key: 'iva',
        render: (iva) => <span>{iva} %</span>,
        sorter: (a, b) => a.iva - b.iva,
      },
      {
        title: 'Subtotal c/IVA',
        dataIndex: 'totalIvaProdcutPrice',
        key: 'totalIvaProdcutPrice',
        render: (totalIvaProdcutPrice) => (
          <span>
            {totalIvaProdcutPrice?.toLocaleString('es-AR', {
              style: 'currency',
              currency: 'ARS',
              minimumFractionDigits: 2,
            })}
          </span>
        ),
        sorter: (a, b) => a.totalIvaProdcutPrice - b.totalIvaProdcutPrice,
      }
    );

  return (
    <Table
      title={header}
      columns={columns}
      dataSource={search ? itemsFilters : items}
      scroll={{ x: 500 }}
      pagination={false}
      locale={{ emptyText: 'No se encontraron datos' }}
      summary={(pageData) => {
        let totalQuantity = 0;
        let totalUnitPrice = 0;
        pageData.forEach(({ quantity, unitPrice }) => {
          totalQuantity += quantity;
          totalUnitPrice += unitPrice;
        });
        if (id)
          return (
            <Table.Summary.Row style={{ backgroundColor: '#002a52' }}>
              <Table.Summary.Cell index={0}>
                <span style={{ color: '#fff' }}>Total</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <span style={{ color: '#fff' }}>
                  {Math.abs(totalQuantity).toLocaleString('es-AR')}
                </span>
              </Table.Summary.Cell>
              {type === '#buy' && (
                <>
                  <Table.Summary.Cell index={2}>
                    <span style={{ color: '#fff' }}>
                      {totalUnitPrice?.toLocaleString('es-AR', {
                        style: 'currency',
                        currency: 'ARS',
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <span style={{ color: '#fff' }}>
                      {items[0]?.subTotalPrice?.toLocaleString('es-AR', {
                        style: 'currency',
                        currency: 'ARS',
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <span style={{ color: '#fff' }}>
                      {items[0]?.totalIva?.toLocaleString('es-AR', {
                        style: 'currency',
                        currency: 'ARS',
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <span
                      style={{
                        fontWeight: 'bold',
                        color: '#fff',
                      }}
                    >
                      {items[0]?.totalPrice?.toLocaleString('es-AR', {
                        style: 'currency',
                        currency: 'ARS',
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </Table.Summary.Cell>
                </>
              )}
            </Table.Summary.Row>
          );
      }}
    />
  );
}
