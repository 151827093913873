import React, { useState, useEffect } from 'react';
import { Form, Button, Input, notification, Select, Col } from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import {
  addConsultationApi,
  editConsultationApi,
} from '../../../api/consultation';
import { addNotePatientApi, editNotePatientApi } from '../../../api/patient';
import { getTemplatesApi } from '../../../api/template';

export default function ConsultationForm(props) {
  const {
    patientId,
    setIsVisibleModal,
    setReload,
    note,
    descriptionNote,
    noteId,
    editConsultation,
    data,
  } = props;

  const [consultationForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    consultationForm.resetFields();
    consultationForm.setFieldsValue({ description: descriptionNote });

    editConsultation &&
      consultationForm.setFieldsValue({
        description: data.description,
        title: data?.title,
      });

    if (!note) {
      let token = getAccessTokenApi();

      getTemplatesApi({ token, type: 'Ambulatorio' })
        .then((response) => {
          setTemplates(response.templates);
        })
        .catch((err) => {});
    }
  }, [descriptionNote, setIsVisibleModal]);

  const onFinish = async (values) => {
    setLoading(true);
    values.patient = patientId;
    let token = await getAccessTokenApi();

    !note
      ? editConsultation
        ? editConsultationApi(data?._id, values, token)
            .then(async (response) => {
              if (response?.code !== 200) {
                notification['error']({ message: response.message });
                setLoading(false);
              } else {
                notification['success']({ message: response.message });
                consultationForm.resetFields();
                setLoading(false);
                setReload(true);
              }
            })
            .catch(() => {
              notification['error']({
                message: 'Error del servidor, intente mas tarde.',
              });
              setLoading(false);
            })
        : addConsultationApi(values, token)
            .then(async (response) => {
              if (response?.code !== 200) {
                notification['error']({ message: response.message });
                setLoading(false);
              } else {
                notification['success']({ message: response.message });
                consultationForm.resetFields();
                setLoading(false);
                setReload(true);
              }
            })
            .catch(() => {
              notification['error']({
                message: 'Error del servidor, intente mas tarde.',
              });
              setLoading(false);
            })
      : descriptionNote
      ? editNotePatientApi(noteId, values, token)
          .then(async (response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
              setLoading(false);
            } else {
              notification['success']({ message: response.message });
              setIsVisibleModal(false);
              consultationForm.resetFields();
              setLoading(false);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
            setLoading(false);
          })
      : addNotePatientApi(values, token)
          .then(async (response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
              setLoading(false);
            } else {
              notification['success']({ message: response.message });
              setIsVisibleModal(false);
              consultationForm.resetFields();
              setLoading(false);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
            setLoading(false);
          });
  };

  return (
    <div>
      <Form form={consultationForm} onFinish={onFinish}>
        {!note && (
          <Form.Item name="title">
            <Input
              placeholder="Tratamiento o motivo consulta (opcional)"
              maxLength={50}
            />
          </Form.Item>
        )}
        {templates?.length > 0 && !editConsultation && (
          <Form.Item name="template">
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder="Templates.. (Opcional)"
              onChange={(id) => {
                let { description } =
                  templates.find((template) => template._id === id) || '';
                consultationForm.setFieldsValue({
                  description,
                });
              }}
            >
              {templates?.map((template, index) => (
                <Select.Option
                  key={index}
                  value={template._id}
                  style={{ textTransform: 'capitalize' }}
                >
                  {template.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="description"
          rules={[{ required: true, message: 'Debe ingresar la consulta' }]}
        >
          <Input.TextArea
            placeholder={
              !note
                ? 'Decripción de la consulta...'
                : 'Notas personales que solo seran visualizadas por mi usuario...'
            }
            autoSize={{ minRows: 10 }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="default"
            htmlType="submit"
            style={{ width: '100%' }}
            disabled={loading}
            loading={loading}
          >
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
